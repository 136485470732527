<template>
  <PetitionLayout
    :previous="previousPage"
    :next="nextPage"
    :priceProtection="petition.priceProtection"
    :title="$t('petition.tellUsAFewThingsAboutYourHouse')"
  >
    <v-form ref="form" v-model="valid" class="form-login px-4" lazy-validation>
      <v-row class="mt-0">
        <v-col cols="12">
          <v-row align-content="start" no-gutters>
            <v-col class="pb-0 px-0 mx-auto" cols="12">
              <v-col
                class="px-0 flex-row align-center pb-0"
                cols="12"
              >
                <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
                  {{ $t('region') }}
                </div>
              </v-col>
              <v-col
                class="px-0 d-flex align-center"
                cols="12"
              >
                <v-text-field
                  v-uppercase
                  v-model="petition.resort.city"
                  class="text-field-border label-class"
                  height="54"
                  :rules="rules.rulesAdress"
                  dense
                  outlined
                  flat
                  background-color="white"
                  :placeholder="$t('petition.regionPlaceholder')"
                ></v-text-field> </v-col
            ></v-col>
            <v-col class="pb-0 px-0 mx-auto" md="6" cols="12">
              <v-col
                class="px-0 flex-row align-center pb-0"
                cols="12"
              >
                <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
                  {{ $t('street') }}
                </div>
              </v-col>
              <v-col
                class="px-0 pr-md-4 d-flex align-center"
                cols="12"
              >
                <v-text-field
                  v-uppercase
                  v-model="petition.resort.streetName"
                  height="54"
                  :rules="rules.rulesAll"
                  class="text-field-border label-class"
                  dense
                  outlined
                  flat
                  background-color="white"
                  :placeholder="$t('petition.streetPlaceholder')"
                ></v-text-field> </v-col
            ></v-col>
            <v-col class="py-0 px-0 mx-auto" md="6" cols="12">
              <v-col
                class="pl-0 flex-row align-center pb-0"
                cols="12"
              >
                <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
                  {{ $t('streetNumber') }}
                </div>
              </v-col>
              <v-col class="px-0 d-flex align-center" cols="12">
                <v-text-field
                  v-uppercase
                  v-model="petition.resort.streetNumber"
                  height="54"
                  :rules="rules.rulesAll"
                  class="text-field-border label-class"
                  dense
                  outlined
                  flat
                  background-color="white"
                  :placeholder="$t('petition.streetNumberPlaceholder')"
                ></v-text-field> </v-col
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12">
          <v-row>
            <v-col class="flex-row align-center" md="10" cols="12">
              <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
                {{ $t('petition.postalCode') }}
              </div>
            </v-col>
            <v-col class="d-flex align-center pt-0" cols="12">
              <v-text-field
                type="number"
                v-model="petition.postalCode"
                ref="tk"
                :rules="rules.rulesTK"
                class="text-field-border pt-0"
                dense
                outlined
                flat
                background-color="white"
                :placeholder="$t('petition.postalCodePlaceholder')"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-row class="align-center">
            <v-col class="flex-row align-center mt-0" cols="12">
              <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
                {{ $t('petition.mainSpacesArea') }}
              </div>
            </v-col>
            <v-col class="d-flex align-center pt-0" cols="12">
              <v-text-field
                type="number"
                class="text-field-border pt-0"
                :messages="[$t('petition.anyTimeCanInsureHousesMsg')]"
                v-model="petition.sqMeters"
                ref="emb"
                :rules="rules.rulesEMB"
                :suffix="$t('suffixSqm')"
                dense
                outlined
                flat
                background-color="white"
                :placeholder="$t('petition.sqMetersPlaceholder')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-row>
            <v-col class="flex-row align-center" md="12" cols="12">
              <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
                {{ $t('petition.buildYear') }}
              </div>
            </v-col>
            <v-col class="d-flex align-center pt-0" cols="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                nudge-bottom="52"
                :max-width="$vuetify.breakpoint.lgAndUp ? 664 : 'auto'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="text-field-border pt-0"
                    dense
                    ref="year"
                    readonly
                    :rules="rules.rulesYear"
                    v-model="petition.builtYear"
                    outlined
                    name="built-date"
                    v-bind="attrs"
                    v-on="on"
                    flat
                    background-color="white"
                    :placeholder="$t('petition.buildYearPlaceholder')"
                    :hint="$t('petition.homesBuildBeforeTooltip')"
                    persistent-hint
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  type="month"
                  no-title
                  ref="picker"
                  :locale="$i18n.locale == 'en' ? 'en' : 'el'"
                  v-model="date"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1960"
                  @click:year="save"
                  full-width
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="petition.code != 212">
        <v-col sm="11" xs="10">
          <v-row no-gutters>
              <v-col class="d-flex align-center pa-0" md="12" cols="12">
                <div
                  v-if="petition.code != 4682"
                  class="font-size-card-title line-height-title purple-dark--text font-weight-bold"
                >
                  {{ $t('advisorForm.ownerNumber') }}
                </div>
                <div
                  v-else
                  class="font-size-card-title line-height-title purple-dark--text font-weight-bold"
                >
                  {{ $t('advisorForm.ownerNumberLoss') }}
                </div>
              </v-col>
              <v-col
                class="d-flex justify-start justify-md-start px-0 pt-2"
                md="12"
                cols="12"
              >
                <v-btn-toggle
                  mandatory
                  v-model="petition.resort.ownerNumber"
                  class="flex-row rounded-0"
                  :class="{
                    ' flex-row align-center': $vuetify.breakpoint.mdAndUp,
                  }"
                >
                  <v-btn
                    value="1"
                    @click="checkAmount"
                    class="toggle-buttons mr-2 rounded-lg"
                    >1</v-btn
                  >
                  <v-btn
                    value="2"
                    @click="checkAmount"
                    class="toggle-buttons mr-2 rounded-lg"
                    >2
                  </v-btn>
                  <v-btn
                    value="3"
                    @click="checkAmount"
                    class="toggle-buttons mr-2 rounded-lg"
                    >3
                  </v-btn>
                </v-btn-toggle>
              </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row class="bottom-border py-5">
            <v-col class="d-flex align-center" cols="12">
              <div
                class="font-size-card-title line-height-title purple-dark--text font-weight-bold mb-0"
              >
                {{ $t('petition.residenceType') }}
              </div>
            </v-col>
            <v-col class="d-flex align-center pt-0" cols="12">
              <v-btn-toggle
                mandatory
                v-model="petition.resortType"
                color="primary"
                class="d-flex full-width"
              >
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-btn
                      width="100%"
                      value="1"
                      text
                      class="toggle-buttons text-none py-15 rounded-xl"
                    >
                      <v-img
                        max-width="46"
                        contain
                        src="/insuranceIcons/houseIcon.svg"
                        class="mr-2"
                      ></v-img>
                      <span class="text-h5 font-weight-bold ml-4">{{ $t('petition.detachedHouse') }}</span></v-btn
                    >
                    <p class="purple-dim--text mt-2">{{ $t('petition.detachedHouseMsg') }}</p>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-btn
                      width="100%"
                      value="2"
                      text
                      class="toggle-buttons text-none py-15 rounded-xl"
                    >
                      <v-img
                        max-width="48"
                        contain
                        src="/insuranceIcons/BlockOfFlats.svg"
                      ></v-img>
                      <span class="text-h5 font-weight-bold ml-4">{{ $t('petition.blockOfFlats') }}</span>
                    </v-btn>
                    <p class="purple-dim--text mt-2">{{ $t('petition.blockOfFlatsMsg') }}</p>
                  </v-col>
                </v-row>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="petition.code != 212">
        <v-col>
          <v-row>
            <v-col class="flex-row align-center mt-5 pb-4" md="10" cols="12">
              <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
                {{ $t('petition.securityAmount') }}
              </div>
            </v-col>
            <v-col class="d-flex align-center pt-0" cols="12">
              <v-text-field
                v-if="petition.code === 4682"
                v-format-price-input
                :hint="$t('petition.securityAmountTooltip')"
                persistent-hint
                class="text-field-border pt-0"
                append-icon="mdi-currency-eur"
                v-model="petition.pricePerSqMeter"
                :rules="rules.rulesAmount"
                ref="amount"
                dense
                outlined
                flat
                background-color="white"
                :placeholder="$t('petition.securityAmountPlaceholder')"
              ></v-text-field>
              <v-text-field
                v-else
                v-format-price-input
                :hint="$t('petition.insuredAmountBuildingRange')"
                persistent-hint
                class="text-field-border pt-0"
                append-icon="mdi-currency-eur"
                v-model="petition.pricePerSqMeter"
                :rules="rules.rulesAmount2"
                background-color="white"
                ref="amount"
                dense
                outlined
                flat
                :placeholder="$t('petition.insuredAmountPlaceholder')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="petition.code == 215 || petition.code == 212">
        <v-col sm="12">
          <v-row>
            <v-col class="flex-row align-center mt-5 pb-0" cols="12">
              <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
                {{ $t('petition.insuredAmount') }}
              </div>
            </v-col>
            <v-col class="d-flex align-center" cols="12">
              <v-text-field
                v-format-price-input
                :hint="$t('petition.insuredAmountTooltip')"
                persistent-hint
                class="text-field-border pt-0"
                append-icon="mdi-currency-eur"
                v-model="petition.priceContent"
                :rules="rules.rulesContent"
                ref="content"
                dense
                outlined
                flat
                background-color="white"
                placeholder="π.χ. 18450"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar
      rounded="lg"
      elevation="0"
      top
      color="#FFF0EF"
      class="error-snackbar"
      light
      v-model="snackbar"
    >
      <v-icon color="error">mdi-information-outline</v-icon
      ><span class="error--text ml-2">
        {{ $t('advisorForm.pleaseFillOutTheForm') }}</span
      >
    </v-snackbar>
  </PetitionLayout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import PetitionLayout from '@/components/layouts/PetitionLayout.vue';
import rules from '@/helpers/validation-rules';

export default {
  name: 'Petition',
  data() {
    return {
      date: null,
      menu: false,
      snackbar: false,
      valid: true,
      rules: {},
    };
  },
  components: {
    PetitionLayout,
  },
  mounted() {
    this.petition.code = 4682;
    if(this.$i18n.locale == 'gr') {
      this.petition.title = 'Απώλεια μισθωμάτων';
    } else {
      this.petition.title = 'Loss of Rents';
    }

    if(this.$route.params.code) {
      let code = parseInt(this.$route.params.code);

      switch(code) {
        case 4682:
          this.petition.code = code;
          this.petition.title = 'Απώλεια μισθωμάτων'
          break;
        case 209:
          this.petition.code = code;
          this.petition.title = 'Ασφάλεια κτιρίου'
          break;
        case 212:
          this.petition.code = code;
          this.petition.title = 'Ασφάλεια περιεχομένου'
          break;
        case 215:
          this.petition.code = code;
          this.petition.title = 'Ασφάλεια κτιρίου & περιεχομένου'
          break;
        default:
          this.petition.code = 4682;
          this.petition.title = 'Απώλεια μισθωμάτων'
      }
    }
    this.setPetition(this.petition);

    if (this.petition.code == 4682) {
      this.$axios
        .post(`get-quotation-service`, { code: 3030 })
        .then(({ data }) => {
          this.setPriceProtection(data);
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log(err);
          this.setLoading(false);
        });
    }
    this.checkAmount();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.internalActivePicker = 'YEAR'));
    },
    petition: {
      deep: true,
      handler() {
        this.rules = {};
      },
    },
  },
  computed: {
    ...mapGetters(['petition', 'loading']),
  },
  methods: {
    ...mapMutations([
      'setLoading',
      'setPriceProtection',
      'setPetition',
      'moveToNextStep',
      'moveToPreviousStep',
    ]),

    save(date) {
      this.petition.builtYear = date;
      this.$refs.menu.save(date);
    },
    previousPage() {
      this.petition.hasProtection = false;
      this.$router.back();
    },
    nextPage() {
      this.rules = rules(this);

      this.$nextTick(() => {
        if (!this.$refs.form.validate()) {
          return (this.snackbar = true);
        }

        this.moveToNextStep();
        this.$router.push('/about-owner/1');
      });
    },
    checkAmount() {
      if (this.petition.code == 212) {
        this.petition.resort.ownerNumber = 1;
      }
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.petition.resort.ownerNumber > 1) {
            return (this.petition.resort.owners[0].amount = 0);
          }
          if (this.petition.resort.ownerNumber == 1) {
            return (this.petition.resort.owners[0].amount = 100);
          }
        });
      }, 100);
    },
  },
};
</script>

<style>
.v-input__append-inner {
  margin-top: 14px !important;
}
</style>
