export default context => {
  return {
    rulesAll: [
      value => !!value || context.$t('rules.required'),
      value =>
        /^[A-Z α-ω Α-Ω Ά-Ώ ά-ώ a-z 0-9 -]+$/.test(value) ||
        context.$t('rules.specialCharacters'),
    ],
    rulesAdress: [
      value => !!value || context.$t('rules.required'),
      value =>
        /^[A-Z α-ω Α-Ω Ά-Ώ ά-ώ a-z -]+$/.test(value) ||
        context.$t('rules.specialCharactersNumbers'),
    ],
    rulesTK: [
      value => !!value || context.$t('rules.required'),
      value => (value && value.length == 5) || context.$t('rules.postalCode'),
    ],
    rulesEMB: [
      value => !!value || context.$t('rules.required'),
      value => (value >= 30 && value <= 400) || context.$t('rules.EMB'),
    ],
    rulesYear: [value => !!value || context.$t('rules.required')],
    rulesAmount: [
      value => !!value || context.$t('rules.required'),
      value =>
        context.petition.sqMeters
          ? (value.replace('.', '') >= 950 * context.petition.sqMeters &&
              value.replace('.', '') <= 1400 * context.petition.sqMeters) ||
            context.$t('rules.amount', {
              valueFrom: 950 * context.petition.sqMeters,
              valueTo: 1400 * context.petition.sqMeters,
            })
          : (value.replace('.', '') >= 950 && value.replace('.', '') <= 1400) ||
            context.$t('rules.amount', {
              valueFrom: 950,
              valueTo: 1400,
            }),
    ],
    rulesAmount2: [
      value => !!value || context.$t('rules.required'),
      value =>
        context.petition.sqMeters
          ? (value.replace('.', '') >= 1100 * context.petition.sqMeters &&
              value.replace('.', '') <= 1600 * context.petition.sqMeters) ||
            context.$t('rules.amount', {
              valueFrom: 1100 * context.petition.sqMeters,
              valueTo: 1600 * context.petition.sqMeters,
            })
          : (value.replace('.', '') >= 1100 &&
              value.replace('.', '') <= 1600) ||
            context.$t('rules.amount', {
              valueFrom: 1100,
              valueTo: 1600,
            }),
    ],
    rulesContent: [
      value => !!value || context.$t('rules.required'),
      value =>
        (value.replace('.', '') >= 150 * context.petition.sqMeters &&
          value.replace('.', '') <= 450 * context.petition.sqMeters) ||
        context.$t('rules.amount', {
          valueFrom: 150 * context.petition.sqMeters,
          valueTo: 450 * context.petition.sqMeters,
        }),
    ],
  };
};
